import React from 'react'
import Link from 'next/link'
import {
  AddToCartAndQuantity,
  AddToCartProps,
  Chip,
  ChipProps,
  Column,
  Container,
  Link as LinkComponent,
  ListPrice,
  pageTypeProp,
  Picture,
  ProductCertificates,
  Row,
  ShelfLabel,
  Spacer,
  Text,
  Title
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { isValidArrayWithData, useMobile } from '@smu-chile/pkg-unimarc-hooks'
import {
  Certificates,
  Product,
  Warnings
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProducts'
import { normalizeString } from 'shared/helpers'
import { ProductDetailFormat } from './ProductDetailFormat'
import { getGeneralProps } from '../helpers'
import styles from './ProductDetail.module.css'
import { statusPromotionsV2 } from '@smu-chile/pkg-unimarc-components/helpers/promotionsOfShelvesV2'

export interface DetailProps {
  hasAlcohol?: boolean
  certificates?: Array<Certificates>
  pageType?: pageTypeProp
  product: Product
  propsAddToCart: AddToCartProps
  stock: boolean
  promotion?: { image?: string } & ChipProps
  showMoreCertificates?: boolean
  warnings?: Array<Warnings>
  isLoading?: boolean
  handleCertificates?: () => void
}

export const ProductDetail = ({
  hasAlcohol,
  certificates,
  isLoading,
  pageType,
  product,
  propsAddToCart,
  showMoreCertificates,
  stock,
  warnings,
  handleCertificates
}: DetailProps): React.ReactElement => {
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280
  const {
    isPaymentsMethods,
    isPromotion,
    offer,
    ppum,
    price,
    pricePaymentsMethods,
    pricePromotion
  } = statusPromotionsV2(product)
  const { underlineInOffer, validateListPrice, validateFormat } =
    getGeneralProps(product, isMobile, isPromotion)

  const ListPriceProps = {
    isMxNPromotion: product?.promotion?.['type'] === 'mxn',
    isPaymentsMethods,
    isPromotion,
    listPrice: product?.priceDetail?.listPrice,
    membershipData: product?.priceDetail?.membership,
    offer,
    ppum,
    ppumListPrice: product?.priceDetail?.ppumListPrice,
    price,
    priceDetail: product?.priceDetail,
    pricePaymentsMethods,
    pricePromotion,
    saving: product?.promotion?.['saving'],
    title: product?.['item']?.name || product?.name,
    typePromotion4: product?.priceDetail?.promotionType === 'mx$',
    typePromotion5: product?.priceDetail?.promotionType === 'm%n',
    underlineInOffer: underlineInOffer,
    validateListPrice
  }
  const isMembership = isValidArrayWithData(ListPriceProps?.membershipData)
  const promotion = {
    ...product?.priceDetail?.promotionalTag,
    backgroundColor: product?.priceDetail?.promotionalTag?.color,
    label: product?.priceDetail?.promotionalTag?.text,
    color: product?.priceDetail?.promotionalTag?.textColor
  }
  return (
    <>
      <BigScreen>
        <Container
          gap='8px'
          isWrap
        >
          {/* Cucardas */}
          <ShelfLabel
            altText={`${product?.['descriptionShort']} ${product?.brand} ${validateFormat}`}
            chip={{
              font: 18,
              padding: '0',
              borderRadius: getGlobalStyle('--border-radius-lg')
            }}
            isMembership={isMembership}
            isMobile={isMobile}
            pageType={pageType}
            place='PDP'
            position='initial'
            promotion={promotion}
            promotionContainerPadding='0px 0px 10px 0px'
            provider={product?.['provider']}
          />
          {/* product without stock */}
          {!stock && (
            <Row
              backgroundColor={getGlobalStyle('--color-neutral-gray-light')}
              borderRadius='20px'
              justifyContent='center'
              padding='4px'
              width='fit-content'
            >
              <Text
                color='grayNeutral'
                fontSize='md'
                fontWeight='medium'
              >
                Pronto disponible
              </Text>
            </Row>
          )}
          {promotion && stock && (
            <Container
              absoluteDefault='topLeft'
              justifyContent='start'
            >
              {!promotion?.image ? (
                <Chip
                  {...promotion}
                  borderRadius={getGlobalStyle('--border-radius-lg')}
                  fontSize={16}
                  isMembership={isMembership}
                  isMobile={isMobile}
                  place='PDP'
                />
              ) : (
                <Picture
                  alt={promotion?.label}
                  border='none'
                  borderRadius='none'
                  height='25px'
                  src={promotion?.image}
                  width='100%'
                />
              )}
            </Container>
          )}
          {/* brand */}

          <Row>
            <LinkComponent
              href={`/brand/${normalizeString(product?.brand)}`}
              underline='none'
              wrapper={Link}
            >
              <Text
                clickable='pointer'
                color='black'
                customClassName={styles.textBrand}
                fontSize='lg'
                fontWeight='semibold'
                type='p'
              >
                {product?.brand?.toLowerCase()}
              </Text>
            </LinkComponent>
          </Row>
          {/* name */}

          <Row>
            <Title
              customFontSize={getGlobalStyle('--font-size-titles-md')}
              fontWeight='regular'
            >
              {product?.name}
            </Title>
          </Row>

          {/* format */}
          <Row>
            <ProductDetailFormat
              container={product?.['container']}
              degrees={product?.['degrees']}
              isMobile={isMobile}
              validatedFormat={validateFormat}
              volume={product?.['volume']}
            />
          </Row>

          {/* sku */}
          <Row>
            <Text
              color='gray'
              fontSize='xs'
              fontWeight='regular'
              type='p'
            >
              Sku: {product?.refId || product?.sku}
            </Text>
          </Row>

          {/* product with stock */}
          {stock && (
            <>
              <Spacer.Horizontal size={16} />
              <ListPrice
                {...ListPriceProps}
                place='PDP'
              />
            </>
          )}

          {/* addToCart button */}
          <Row padding='90px 0 30px 0'>
            <AddToCartAndQuantity
              {...propsAddToCart}
              isEditable={false}
              source='pdp'
            />
          </Row>

          {/* Product certificates and warnings */}
          {isValidArrayWithData(certificates) && (
            <Row>
              <ProductCertificates
                certificates={certificates}
                hasAlcohol={hasAlcohol}
                isLoading={isLoading}
                isOpen={showMoreCertificates}
                onClickShowLessMore={handleCertificates}
                warnings={warnings}
              />
            </Row>
          )}
        </Container>
      </BigScreen>

      <SmallScreen>
        <Column>
          {stock && (
            <ShelfLabel
              altText={`${product?.['descriptionShort']} ${product?.brand} ${validateFormat}`}
              chip={{ font: 12, padding: '1.5px 4px', borderRadius: '20px' }}
              isMembership={isMembership}
              isMobile={isMobile}
              place='PDP'
              position='initial'
              promotion={promotion}
              promotionContainerPadding='0px 0px 10px 0px'
              provider={product?.['provider']}
            />
          )}
          {/* product without stock */}
          {!stock && (
            <Row
              backgroundColor={getGlobalStyle('--color-neutral-gray-light')}
              borderRadius='20px'
              justifyContent='center'
              margin='0 0 4px 0'
              padding='1.5px 4px'
              width='fit-content'
            >
              <Text
                color='grayNeutral'
                fontSize='sm'
                fontWeight='semibold'
                lineHeight='15px'
              >
                Pronto disponible
              </Text>
            </Row>
          )}
          {/* brand */}
          <LinkComponent
            href={`/brand/${normalizeString(product?.brand)}`}
            underline='none'
            wrapper={Link}
          >
            <Text
              clickable='pointer'
              color='black'
              customClassName={styles.textBrand}
              fontSize='lg'
              fontWeight='bold'
              type='p'
            >
              {product?.brand?.toLowerCase()}
            </Text>
          </LinkComponent>

          <Spacer.Horizontal size={4} />

          {/* name */}
          <Text
            color='black'
            fontSize='xl'
            fontWeight='regular'
            textAlign='left'
            type='p'
          >
            {product?.name}
          </Text>

          <Spacer.Horizontal size={4} />

          {/* format */}
          <ProductDetailFormat
            container={product?.['container']}
            degrees={product?.['degrees']}
            isMobile={isMobile}
            validatedFormat={validateFormat}
            volume={product?.['volume']}
          />
          <Spacer.Horizontal size={4} />

          {/* sku */}
          <Text
            color='gray'
            fontSize='sm'
            fontWeight='regular'
            type='p'
          >
            Sku: {product?.refId || product?.sku}
          </Text>

          <Spacer.Horizontal size={16} />

          {/* product with stock */}
          {stock && (
            <>
              <Spacer.Horizontal size={4} />
              <ListPrice
                {...ListPriceProps}
                isMobile={isMobile}
                place='PDP'
              />
            </>
          )}
        </Column>
      </SmallScreen>
    </>
  )
}
