import { useEffect, useState } from 'react'
import {
  getCookie,
  isValidArrayWithData,
  setCookie,
  trigger
} from '@smu-chile/pkg-unimarc-hooks'
import { updateSalesChannel } from 'shared/helpers'

interface LocalAddress {
  data?: {
    city?: string
    country?: string
    neighborhood?: string
    postalCode?: string
    state?: string
    street?: string
    complement?: string
    geoCoordinate?: number[]
    number?: string
    placeId?: string
    receiverName?: string
    reference?: string
    userId?: string
    addressType?: string
  }
  responseData?: {
    salesChannel?: string
    reference?: string
    number?: string
    geoCoordinates?: number[]
    complement?: string
  }
  saveAddresses?: LocalOrderFormAddress[]
}

interface LocalOrderFormAddress {
  addressType?: string
  receiverName?: string
  addressId?: string
  isDisposable?: boolean
  postalCode?: string
  city?: string
  state?: string
  country?: string
  street?: string
  number?: string
  neighborhood?: string
  complement?: string
  reference?: string
  geoCoordinates?: number[]
}

interface LocalOrderForm {
  data?: {
    userProfileId?: string
    clientProfileData?: {
      firstName?: string
      lastName?: string
    }
    selectedAddresses?: LocalOrderFormAddress
    availableAddresses?: LocalOrderFormAddress[]
    removedProducts?: {
      id: string
      message: string
      image: string
      code: string
    }[]
  }
}

export interface LocalOrderFormQuery {
  data?: LocalOrderForm
}

/**
 * This hook handle all sessionStorage address and local orderfrom
 * @returns {LocalAddress} Return a LocalAddress object
 * @returns {LocalOrderForm} Return a LocalOrderForm object
 * @returns {saveLocalAddress} Return a function that process and save local address into sessionStorage
 * @returns {clearLocalAddress} Return a function that delete data from sessionStorage
 */
export const useLocalAddress = () => {
  const [hasLocalAddress, setHasLocalAddress] = useState(false)
  const [localAddress, setLocalAddress] = useState<LocalAddress>(null)
  const [localOrderForm, setLocalOrderform] = useState<LocalOrderForm>({
    data: {
      userProfileId: 'guest-user',
      clientProfileData: { firstName: 'guest', lastName: 'user' },
      removedProducts: []
    }
  })
  const [localQueryOrderForm, setLocalOrderformQuery] =
    useState<LocalOrderFormQuery>({})
  const [localSla, setLocalSla] = useState(undefined)
  const [localSc, setLocalSc] = useState(undefined)

  const transformAddress = (address) => {
    const geoCoordinates = address?.responseData?.geoCoordinates
      ? address.responseData.geoCoordinates
      : address.data.geoCoordinates
    return {
      ...address?.data?.selectedAddresses,
      addressId: address?.addressId
        ? address.addressId
        : `local-${address.data.postalCode}`,
      geoCoordinates: geoCoordinates,
      reference: address.responseData?.reference,
      receiverName: address.data.receiverName,
      postalCode: address.data.postalCode,
      city: address.data.city,
      state: address.data.state.toUpperCase(),
      country: address.data.country,
      street: address.data.street,
      number: address.data.number,
      neighborhood: address.data.neighborhood,
      complement: address.data?.complement,
      addressName: `${address.data.street} ${address.data.number}`,
      addressType: address.data.addressType,
      geoCoordinate: geoCoordinates,
      id: `local-${address.data.postalCode}`
    }
  }

  const getRemovedProducts = (sla) => {
    const removedProducts = {
      removedProducts: isValidArrayWithData(sla.data.messages)
        ? sla.data.messages
            .map((item) => {
              return {
                id:
                  item?.fields?.id ||
                  sla?.data?.items?.[parseInt(item?.fields?.itemIndex)]?.id,
                message: item.text,
                image: undefined,
                code: item.code
              }
            })
            .filter((item) => {
              return item.id !== process.env.NEXT_PUBLIC_PRODUCT_DUMMY
            })
            .filter((item, index, self) => {
              return (
                self.findIndex((v) => {
                  return v.id === item.id
                }) === index
              )
            })
            .filter((item) => {
              return item.id
            })
        : []
    }

    return removedProducts
  }

  const removeLocalProducts = (items, domain, router) => {
    const cart = getCookie('addToCart')
    const localCart = cart && JSON.parse(cart)
    const filteredCart = isValidArrayWithData(localCart)
      ? localCart.filter((item) => {
          return !items
            .map((localItem) => {
              return localItem.id
            })
            .includes(item.id)
        })
      : []

    setCookie({
      cookieName: 'addToCart',
      cookieValue: JSON.stringify(filteredCart),
      options: {
        domain,
        path: '/'
      }
    })
    router.reload()
  }

  const saveAddresses = (addressObj) => {
    if (!localAddress?.saveAddresses) return [transformAddress(addressObj)]

    return [...localAddress.saveAddresses, transformAddress(addressObj)].filter(
      (value, index, self) => {
        return (
          self.findIndex((v) => {
            return v.id === value.id
          }) === index
        )
      }
    )
  }

  const saveLocalAddress = (addressObj, router?) => {
    const address = {
      ...addressObj,
      saveAddresses: saveAddresses(addressObj)
    }
    setHasLocalAddress(true)
    setLocalAddress(address)
    sessionStorage.setItem('localAddress', JSON.stringify(address))
    setCookie({
      cookieName: 'selectedAddresses',
      cookieValue: JSON.stringify(address)
    })
    updateSalesChannel(addressObj.responseData.salesChannel)
    router && router.reload()
  }

  const clearLocalAddress = () => {
    sessionStorage.removeItem('localAddress')
    setHasLocalAddress(false)
    setLocalOrderform({})
    setLocalOrderformQuery({})
  }

  useEffect(() => {
    if (window && sessionStorage.getItem('localAddress') && !hasLocalAddress) {
      const sessionLocalAddress = JSON.parse(
        sessionStorage.getItem('localAddress')
      )
      setHasLocalAddress(true)
      setLocalAddress(sessionLocalAddress)
    }
  }, [])

  useEffect(() => {
    if (localAddress) {
      setLocalSc(localAddress.responseData.salesChannel)
      const selectedAddress = transformAddress(localAddress)
      setLocalOrderform({
        data: {
          ...localOrderForm.data,
          selectedAddresses: { ...selectedAddress },
          availableAddresses: localAddress?.saveAddresses.filter((address) => {
            return address.addressType === 'residential'
          })
        }
      })
    }
  }, [localAddress, localSla])

  useEffect(() => {
    setLocalOrderformQuery({
      data: localOrderForm
    })
  }, [localOrderForm])

  useEffect(() => {
    trigger({
      eventType: 'localAddressStoreLocator',
      data: {
        hasLocalAddress,
        localQueryOrderForm,
        localOrderForm,
        localSla
      }
    })
  }, [
    hasLocalAddress,
    localAddress,
    localOrderForm,
    localQueryOrderForm,
    localSla
  ])

  return {
    hasLocalAddress,
    localAddress,
    localOrderForm,
    localSc,
    saveLocalAddress,
    clearLocalAddress,
    setLocalSla,
    getRemovedProducts,
    removeLocalProducts
  }
}
