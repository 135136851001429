import { postNewAddress, useUpdateAddress } from '@smu-chile/pkg-unimarc-hooks'
import { useEffect } from 'react'

/**
 * This hook is  fot update the address from local storage
 * @param data ResponseOrderFormProps
 * @param router next router
 * @param isLoading boolean
 * @param isLoggedIn boolean
 */

export const useUpdateAddressFromLocal = async (
  data,
  router,
  isLoading,
  isLoggedIn
) => {
  const { handleAddressObject } = useUpdateAddress(router)

  useEffect(() => {
    const updateAddress = async () => {
      const localAddress = JSON.parse(sessionStorage.getItem('localAddress'))
      const {
        data: {
          userProfileId,
          clientProfileData: { firstName, lastName }
        }
      } = data

      const addressObject = {
        city: localAddress.data.city,
        state: localAddress.data.state,
        country: localAddress.data.country,
        street: localAddress.data.street,
        number: localAddress.data.number,
        neighborhood: localAddress.data.neighborhood
      }

      if (localAddress?.data?.addressType === 'residential') {
        const responseNewAddress = await postNewAddress({
          ...addressObject,
          geoCoordinate: localAddress.data.geoCoordinate,
          complement: localAddress.data.complement,
          placeId: localAddress.data.placeId,
          postalCode: localAddress.data.postalCode,
          receiverName: `${firstName} ${lastName}`,
          reference: localAddress.data.reference,
          userId: userProfileId
        })

        const isError = responseNewAddress?.data['error']

        if (typeof isError === 'boolean' && !isError) {
          handleAddressObject({
            ...addressObject,
            street:
              localAddress.data.street.length === 0
                ? localAddress.data.state
                : localAddress.data.street,
            addressId: responseNewAddress.data['data'].addressName,
            addressType: responseNewAddress.data['data'].addressType,
            complement: localAddress.data.complement || '',
            reference: localAddress.data.reference,
            geoCoordinates: localAddress.data.geoCoordinate,
            receiverName: `${firstName} ${lastName}`,
            salesChannel: localAddress.responseData.salesChannel
          })
          sessionStorage.removeItem('localAddress')
        }
      } else if (localAddress?.data?.addressType === 'pickup-in-point') {
        handleAddressObject({
          ...addressObject,
          addressType: localAddress.data.addressType,
          geoCoordinates: localAddress.data.geoCoordinates,
          addressId: localAddress.data.addressId,
          receiverName: `${firstName} ${lastName}`,
          salesChannel: localAddress.responseData.salesChannel
        })
        sessionStorage.removeItem('localAddress')
      }
    }
    !isLoading &&
      isLoggedIn &&
      sessionStorage.getItem('localAddress') &&
      updateAddress()
  }, [isLoggedIn, isLoading])
}
