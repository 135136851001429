// https://it-smu.atlassian.net/wiki/spaces/Ecommerce1/pages/787644419/Subscripci+n
export const MEMBERSHIP_LEVELS = {
  oro: {
    url: 'images/socioOro.png',
    alt: 'imagen de socio oro',
    title: 'socio oro'
  },
  platino: {
    url: 'images/socioPlatino.png',
    alt: 'imagen de socio platino',
    title: 'socio platino',
    tipo: 'socio platino'
  },
  diamante: {
    url: 'images/socioDiamante.png',
    alt: 'imagen de socio diamante',
    title: 'socio diamante',
    tipo: 'socio diamante'
  },
  clubunimarc: {
    url: 'images/socioClub.png',
    alt: 'imagen de socio club',
    title: 'socio club',
    tipo: 'socio club'
  }
}

export const OFFERS_URL = '4yVpIe3YBiZeZlhV8Q65VQ'

export const CAMPAING_APP_CHIPS_UNIMARC = {
  0: 'bW8BAmjWGBidoJjqdWWp7'
}
export const ID_HEADER_MENU_BUTTONS = {
  0: '56SGwq2UnvyvygqTnEU4kn'
}

export const NO_RESULT_CHEESE = {
  0: '1vizXuVG2ZAEvLBoD9hM6A'
}

export const MENU_HEADER_MENU_BUTTONS = {
  0: '7FKPzLEKEXEaQAlWR1Nx9F', //mis compras
  1: 'sk4g47CIf22WR9Jmv4zeK', //mis listas
  2: '6j2YDNLPRQTH5jmNVwmOKL', //mis medios de pago
  3: '7jXhAAv8B07UA3JovAkHhi', //cerrar sesion
  4: '6Ys6uV0w4nlHHyHks8h1HB', //cupones
  5: '4sRvsLv14mFzhnkJUdWrcT', //mis datos personales
  6: '3nY7pId6PtAYaUAMf0tXop', //necesito ayuda
  7: '1zhNIHmu50U4iOBchs7Xqt', //membersía
  8: '1ZhDS6o1s9jtFzIkIRbKtr', //solicitudes
  9: '200eAJhcoADvSS15MBz3fs' //favoritos
}


// to get images from contenful
export const EMPTY_ORANGE_IMG = {
  0: 'biRXfIqeSvhltdz6EiwEs'
}

export const MODAL_LOGOUT_STRAWBERRY = {
  0: 'bquT0FyHwOW8l0ntqPZpf'
}

export const VALIDATE_SC_MODAL_IMAGE = {
  0: '39mfaeyBygzQvDgVq4h7bR'
}

export const SOMETHING_IS_WRONG_MODAL_IMAGE = {
  0: '2TUvQviWSgMU01kL1uMMqW'
}

export const UNIPAY_LOGO = {
  0: '4XNZ6UOaIx7mz4eGLgxHe8'
}

export const TRANSBANK_FOOTER_IMG = {
  0: '3KsF8CJVJWBVpqOykXGv3i'
}

export const MACARONS_ERROR_IMG_V2 = {
  0: '5e1ciNQ3CnN4dUIqEFzJNR'
}

export const ERROR_IMG_V2 =
  'https://unimarcdev.vtexassets.com/arquivos/errorv2.jpg'

export const CONFIRM_ADDRESS_MODAL_IMAGE = {
  0: '5l90l0SrXwdtFFAdmHlH4H'
}

export const TYPE_BANNER_CAROUSEL = 'BANNER-CAROUSEL'

export const MEMBERSHIP_SAVINGS = {
  0: '7qnVTZTlBR73ufsQRqhyJ5'
}

export const RECAPTCHA_ACTIONS = {
  HOMEPAGE: 'homepage',
  LOGIN: 'login',
  SIGNUP: 'signup',
  RESET_PASSWORD: 'reset_password',
  CHECKOUT: 'checkout',
  CONTACT: 'contact',
  SUBMIT_COMMENT: 'submit_comment',
  IDENTITY_LOOKUP: 'identity_lookup'
}

export const FEATURES_FLAGS_IDENTIFIERS = {
  hideDropDownWithRoud: 'hideDropDownWithRoud'
}

export const FACETS_AND_ORDERS_BY_FILTERS = {
  TITLE: 'Filtros'
}

export const ALLOWED_FACETS = [
  'brands',
  'container',
  'foodWorld',
  'priceRange',
  'volume',
  'warningStamps'
]
