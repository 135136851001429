import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  datalayerSimpleEvent,
  generateBreadcrumbJoin,
  getCookie,
  IContentfulResponse,
  isValidArrayWithData,
  setCookie,
  trigger,
  useAndesML,
  useCatalogFacets,
  useCategories,
  usePagination,
  useProductsSearch,
  useSearchV2
} from '@smu-chile/pkg-unimarc-hooks'
import {
  changeUrlCategories,
  contentBannerLegal,
  dataForBreadcrumbs,
  getInitialFilters,
  getQueryKeyToRequest,
  getSubCategoriesFilters,
  matchOptionKey,
  isContinuosFetching,
  paginationCal,
  cleanSearching
} from 'shared/helpers'
import { useFilterCategories, useFilter, useOrder } from 'shared/utils/hooks'
import { getBannerLegal } from './helpers/getBannerLegal'
import { SearchBodyProduct, SearchBodyProductProps } from './SearchBodyProduct'
import { REACT_QUERY_GENERAL } from '../../shared/constants/reactQuery'
import { ErrorPageV2Props } from 'components/ErrorPageV2'
import dynamic from 'next/dynamic'

const ErrorPage = dynamic<ErrorPageV2Props>(
  import('..').then((mod) => {
    return mod.ErrorPageV2
  })
)
interface ProductSearchPagePros {
  bannerLegal?: IContentfulResponse
  isMobile?: boolean
  page: number
  search: string
  categorySlug?: string
}

const initialLimit = 49

export const ProductSearchPage = ({
  bannerLegal,
  isMobile,
  search
}: ProductSearchPagePros) => {
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [selectedTab, setSelectedTab] = useState(0)
  const router = useRouter()
  const {
    applyFilters,
    compareAndSetInitialData,
    currentSelectedFilters,
    filterData
  } = useFilter(router) || {}

  const {
    onClickFilter,
    onClickRadioOrderByOption,
    onApplyFilterModal,
    selectedRadio,
    disabledButton
  } = useOrder(router, setShowFiltersModal)

  const { handleClickCategoryPill, place, currentCategory } =
    useFilterCategories(router)
  const { result } = useSearchV2(
    Array.isArray(router.query.q) ? router.query.q[0] : router.query.q
  )
  const page = parseInt(router?.query?.page as string) || 1
  const { offset, end: limit } = paginationCal({ page, limit: 50 })
  const nextLimit = offset + initialLimit
  const [offSet, setOffSet] = useState(offset)
  const [countLimit, setCountLimit] = useState(nextLimit)
  const [products, setProducts] = useState([])
  const [resources, setResources] = useState(Infinity)
  const { breadcrumbData, title } = dataForBreadcrumbs({ router, search })
  const dataBreadCrumb = changeUrlCategories(breadcrumbData)
  const orderByFilterName = matchOptionKey(
    router?.query?.orderBy?.toString() ?? ''
  )
  const isSearchSuggestionsPage = Boolean(router?.query?.suggestions)
  // Clean the object of promtions
  const dataBannerLegal = getBannerLegal({ bannerLegal, search })

  const {
    bannerImages,
    contentLegalParagraph,
    contentLegalTitle,
    searchBannerData
  } = contentBannerLegal(dataBannerLegal)

  const urlLinkOffer = dataBannerLegal?.['items']?.[0]?.fields?.urlRedirect
  const continuosFetching: boolean = isContinuosFetching({
    countLimit,
    resources,
    limit
  })

  const reactQueryBySearch = {
    ...REACT_QUERY_GENERAL
  }

  const initialFilters = getInitialFilters(router?.query)
  const searchWithoutDashes = cleanSearching(search)
  const searchWithDash = search.trim().replace(/\s+/g, '-')
  const keysToQuery = getQueryKeyToRequest([
    ...initialFilters,
    {
      value: {
        ...currentSelectedFilters,
        ...applyFilters
      }
    },
    { value: currentCategory },
    { value: searchWithDash },
    { value: offset.toString() },
    { value: countLimit.toString() },
    { value: orderByFilterName }
  ])
  const productsBySearch = useProductsSearch({
    ...currentSelectedFilters,
    ...applyFilters,
    categories: currentCategory,
    from: offSet.toString(),
    searching: searchWithoutDashes,
    to: countLimit.toString(),
    orderBy: orderByFilterName,
    userTriggered: isSearchSuggestionsPage ? false : true,
    reactQuery: {
      ...reactQueryBySearch,
      queryKey: keysToQuery
    }
  })

  const bannerAndesMl = useAndesML({
    deviceType: isMobile ? 'MOBILE' : 'DESKTOP',
    pageType: 'SRP',
    requestPage: `${process.env.NEXT_PUBLIC_HOMEURL}${router.asPath}`,
    searchText: search.trim(),
    slot: 'TOP BANNERS',
    reactQuery: {
      refetchOnWindowFocus: false
    }
  })

  const responseFacetsData = useCatalogFacets({
    ...currentSelectedFilters,
    ...applyFilters,
    categories: currentCategory,
    searching: searchWithoutDashes,
    reactQuery: {
      ...reactQueryBySearch,
      queryKey: keysToQuery
    }
  })

  const totalQuantity = resources

  const facetsFilterV2 = responseFacetsData?.data || {}

  const propsNavigation = usePagination({
    totalQuantity,
    itemPerPage: 50,
    defaultPage: page,
    handleQuantity: () => {
      const newCountLimit = nextLimit > limit ? limit : nextLimit
      setOffSet(offset)
      setCountLimit(newCountLimit)
    }
  })

  const isLoadingPage = productsBySearch.isLoading
  const isLoadingFacets =
    responseFacetsData?.isLoading || responseFacetsData?.isFetching
  const isLoadingPageAndFacets = isLoadingFacets || isLoadingPage

  const handleFetchProducts = () => {
    if (!productsBySearch.isLoading && continuosFetching) {
      setOffSet(countLimit + 1)
      setCountLimit((prev) => {
        const defaultLimit = limit > resources ? resources : limit
        const nextLimit = prev + initialLimit + 1
        return nextLimit > limit || nextLimit > resources
          ? defaultLimit
          : nextLimit
      })
    }
  }

  const handleOnClickSuggestion = async (suggestion: string) => {
    datalayerSimpleEvent({
      event: 'search',
      event_category: 'busqueda',
      event_action: 'tap_busqueda_sensitiva',
      interactiveTagEvent: 'sugerencia_seleccionada',
      search_type: 'autocompletado',
      search_term: suggestion,
      search_count: totalQuantity.toString()
    })
    const querySuggestion = suggestion.replace(/\s+/g, '-')
    if (router) {
      router.push(`/search?q=${querySuggestion}`)
    } else {
      window.location.href = `/search?q=${querySuggestion}`
    }
  }
  // get all categories menu like with getCategories service
  const resultAisles = useCategories({
    level: 2,
    reactQuery: REACT_QUERY_GENERAL
  })
  const subCategories = getSubCategoriesFilters({
    facetsFilterV2
  })

  const handleToggleFiltersModal = () => {
    setShowFiltersModal(!showFiltersModal)
  }

  const dataOfBodyPageProducts: SearchBodyProductProps = {
    amountOfProducts: products?.length,
    bannerImages,
    bannerAndesMl: bannerAndesMl?.data?.[0] || searchBannerData,
    breadcrumbData: dataBreadCrumb,
    contentLegalParagraph,
    contentLegalTitle,
    facetsFilterV2,
    filterData: { ...filterData, isLoadingFacets: isLoadingPageAndFacets },
    imageAndes: bannerAndesMl?.data?.[0]?.imageUrl,
    isLoadingBannerAndes: bannerAndesMl.isLoading,
    isMobile,
    place,
    products,
    propsNavigation,
    searchBannerData: bannerAndesMl?.data?.[0] || searchBannerData,
    subCategories,
    title,
    totalQuantity,
    urlLinkOffer: bannerAndesMl?.data?.[0]?.['redirectUrl'] || urlLinkOffer,
    setCountLimit: handleFetchProducts,
    onClickFilter,
    onClickCategoryPill: handleClickCategoryPill,
    onClickRadioOrderByOption,
    onClickSuggestion: handleOnClickSuggestion,
    onApplyFilterModal,
    resultAisles: resultAisles,
    searchSuggestions: result?.searchSuggestions?.data?.['suggestions'],
    selectedRadio,
    disabledButton,
    hasFacets: filterData.hasFilter,
    query: search,
    showFiltersModal,
    selectedTab,
    setSelectedTab,
    handleToggleFiltersModal,
    handleOpenFiltersModal: setShowFiltersModal
  }
  useEffect(() => {
    compareAndSetInitialData(facetsFilterV2)
  }, [facetsFilterV2])

  useEffect(() => {
    propsNavigation.setPage(page)
  }, [page])

  useEffect(() => {
    generateBreadcrumbJoin(dataBreadCrumb)
  }, [dataBreadCrumb])

  useEffect(() => {
    trigger({
      eventType: 'onClickPillSuggestions'
    })
  }, [router.query.q])

  // this useEffect is to trigger the search events
  useEffect(() => {
    const datalayer = getCookie('searchDatalayer')
    if (!isLoadingPage && totalQuantity !== Infinity) {
      if (datalayer === 'enter') {
        datalayerSimpleEvent({
          event: 'search',
          event_category: 'busqueda',
          event_action: 'ver_resultados',
          interactiveTagEvent: 'buscador',
          search_type: 'buscador_enter',
          search_term: search,
          search_count: totalQuantity.toString()
        })
      }
      if (datalayer === 'clickMostBuyed') {
        datalayerSimpleEvent({
          event: 'search',
          eventCategory: 'busqueda',
          eventAction: 'tap_los_mas_comprados',
          interactiveTagEvent: 'sugerencia_seleccionada',
          search_type: 'top_compras',
          search_term: search,
          search_count: totalQuantity.toString()
        })
      }
      if (datalayer === 'clickYourSearchs') {
        datalayerSimpleEvent({
          event: 'search',
          eventCategory: 'busqueda',
          eventAction: 'tap_tus_busquedas',
          interactiveTagEvent: 'sugerencia_seleccionada',
          search_type: 'recientes',
          search_term: search,
          search_count: totalQuantity.toString()
        })
      }
      if (router.asPath.includes('suggestions=true')) {
        datalayerSimpleEvent({
          event: 'search',
          event_category: 'busqueda',
          event_action: 'ver_resultados',
          interactiveTagEvent: 'buscador',
          search_type: 'buscador_sensitivo',
          search_count: totalQuantity.toString(),
          search_term: search
        })
      }
      setCookie({ cookieName: 'searchDatalayer', cookieValue: '' })
    }
  }, [isLoadingPage, router.asPath, totalQuantity])

  // Update products per page, there logic to implement the fragment
  // request and show the products in two or more steps
  useEffect(() => {
    const {
      availableProducts = [],
      notAvailableProducts = [],
      resource: count
    } = productsBySearch?.data || {}
    const newProducts = [...availableProducts, ...notAvailableProducts]
    if (count && parseInt(count) !== resources) setResources(parseInt(count))
    setProducts((prev) => {
      const prevData = continuosFetching ? prev : []
      return isLoadingPage && continuosFetching
        ? []
        : [...prevData, ...newProducts]
    })
  }, [productsBySearch?.data, isLoadingPage, countLimit])

  // when service in client-side response with error property
  if (
    !isLoadingPage &&
    (productsBySearch?.isError ||
      (productsBySearch?.error && !productsBySearch?.data?.resource))
  ) {
    return <ErrorPage errorType='400' />
  }

  // when service in client-side response a 404 or no data
  if (
    !isLoadingPage &&
    productsBySearch?.error &&
    !isValidArrayWithData(productsBySearch?.data?.availableProducts)
  ) {
    return <ErrorPage errorType='404' />
  }

  return <SearchBodyProduct {...dataOfBodyPageProducts} />
}
