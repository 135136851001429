import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import {
  generateBreadcrumbJoin,
  usePagination,
  useProductsSearch
} from '@smu-chile/pkg-unimarc-hooks'
import { useOrder } from 'shared/utils/hooks'
import {
  changeUrlCategories,
  getQueryKeyToRequest,
  paginationCal
} from '../../shared/helpers'
import { dataForBreadcrumbs } from 'shared/helpers'
import { ErrorPageSearch } from '../ProductSearchPage/ErrorPageSearch'
import {
  SearchBodyProduct,
  Main
} from 'components/ProductSearchPage/SearchBodyProduct'
import { REACT_QUERY_GENERAL } from 'shared/constants/reactQuery'

interface ProductBrandPros {
  brand: string
  isMobile?: boolean
  testFilters?: boolean
  page: number
  search: string
}
const initialLimit = 49
export const ProductBrandPage = ({
  brand,
  isMobile,
  testFilters,
  page,
  search
}: ProductBrandPros) => {
  const router = useRouter()
  const { breadcrumbData, title } = dataForBreadcrumbs({
    router,
    search,
    brand: true
  })
  const dataBreadCrumb = changeUrlCategories(breadcrumbData)
  const { offset, end: limit } = paginationCal({ page, limit: 50 })
  const nextLimit = offset + initialLimit
  const [offSet, setOffSet] = useState(offset)
  const [countLimit, setCountLimit] = useState(offSet + initialLimit)

  const shelves = []
  const intelliSearchData = useProductsSearch({
    brands: brand && [brand],
    from: offSet.toString(),
    to: countLimit.toString(),
    reactQuery: {
      ...REACT_QUERY_GENERAL,
      queryKey: getQueryKeyToRequest([
        { value: offset },
        { value: countLimit },
        { value: brand }
      ]) as []
    }
  })
  // const intelliSearchData = productsSearchMocks
  const products = [
    ...(intelliSearchData?.data?.availableProducts || []),
    ...(intelliSearchData?.data?.notAvailableProducts || [])
  ]

  const totalQuantity = parseInt(intelliSearchData?.data?.resource) || 0
  const isLoadingPage = intelliSearchData.isLoading

  const propsNavigation = usePagination({
    totalQuantity,
    itemPerPage: 50,
    defaultPage: page,
    handleQuantity: () => {
      const newCountLimit = nextLimit > limit ? limit : nextLimit
      setOffSet(offset)
      setCountLimit(newCountLimit)
    }
  })

  const { onClickRadioOrderByOption } = useOrder(router)

  const dataOfBodyPageProducts = {
    breadcrumbData: dataBreadCrumb,
    title,
    totalQuantity,
    propsNavigation,
    isLoadingPage,
    testFilters,
    products,
    onClickRadioOrderByOption,
    shelves,
    isMobile
  }

  useEffect(() => {
    propsNavigation.setPage(page)
  }, [page])

  useEffect(() => {
    generateBreadcrumbJoin(dataBreadCrumb)
  }, [dataBreadCrumb])

  if (!isLoadingPage && totalQuantity == 0) {
    return (
      <Main>
        <ErrorPageSearch
          message='resultados para la marca'
          search={search}
        />
      </Main>
    )
  }

  return <SearchBodyProduct {...dataOfBodyPageProducts} />
}
