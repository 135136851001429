import { useState } from 'react'
import {
  useEvents,
  useMobile,
  useUpdateFromShelf,
  useSession,
  isValidArrayWithData,
  useProductsMyUsualsV2
} from '@smu-chile/pkg-unimarc-hooks'

import {
  useProdutsCarousel,
  useShelvesData
} from '../helpers/useProdutsCarousel'
import { ContentCarousel } from '../ContentCarousel'
import { ProductCarouselProps } from '../Collection'
import { mapProductsToOldSchema } from 'shared/helpers/mapProducts/mapProductsToOldSchema'
import { useLocalAddress } from 'shared/utils/hooks/useLocalAddress'

export const CarouselMyUsual = ({
  clusterData,
  titleSizes,
  seeAllUrl,
  handleOpenMyListsModal,
  type
}: ProductCarouselProps) => {
  // use
  const { isLoading: sessionLoading } = useSession()
  const { isMobile } = useMobile()
  const { hasLocalAddress } = useLocalAddress()
  const [isOrderFormLoading, setIsOrderFormLoading] = useState(false)
  const {
    isLoading: isLoadingUsuals,
    data,
    error
  } = useProductsMyUsualsV2({
    type: type,
    reactQuery: {
      queryKey: [
        `/catalog/product/search/my-usuals${type ? `/type=${type}` : ''}`
      ]
    }
  })

  const loading = isLoadingUsuals || sessionLoading

  const products = mapProductsToOldSchema(data?.products || [])
  const newListProducts = products?.map((product) => {
    const detailUrl = product?.detailUrl?.split('/')?.[3]
    return {
      ...product,
      detailUrl
    }
  })
  const {
    linkFontSize,
    positionContainer,
    itemsToShow,
    itemsToSlide,
    carouselHeaderPadding,
    spacerBelow
  } = useProdutsCarousel({ isMobile })
  const productLimit = newListProducts?.slice(0, 11)
  const {
    shelvesData,
    editTempOrderForm,
    handleAdd,
    handleChange,
    handleOnClick,
    handleRemove
  } = useUpdateFromShelf({
    products: productLimit,
    isOrderFormLoading,
    hasLocalAddress
  })
  const productsMemo = useShelvesData(shelvesData, isMobile)
  //When the event is called this function there go to read all items that will update and set a new loading state for each item
  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading, items } }) => {
      setIsOrderFormLoading(loading)
      editTempOrderForm(items, loading)
    }
  })

  if (!isValidArrayWithData(data?.products) || error || data?.['error'])
    return null

  const carouselShelData = {
    positionContainer,
    handleOnClick,
    productsMemo,
    itemsToShow,
    handleAdd,
    handleRemove,
    handleChange,
    itemsToSlide,
    spacerBelow,
    handleOpenMyListsModal
  }
  const isLoading: boolean = loading

  return (
    <ContentCarousel
      carouselHeaderPadding={carouselHeaderPadding}
      carouselShelData={carouselShelData}
      clusterData={clusterData}
      isLoading={isLoading}
      isMobile={isMobile}
      linkFontSize={linkFontSize}
      seeAllUrl={seeAllUrl}
      titleSizes={titleSizes}
    />
  )
}
