import {
  formatProductUrl,
  useProductsCoupon
} from '@smu-chile/pkg-unimarc-hooks'
import { useMemo } from 'react'
import { statusPromotions } from 'shared/helpers'
import { FontSizeLink } from '@smu-chile/pkg-unimarc-components/stories/atoms/Links/Link'
import { Sizes } from '@smu-chile/pkg-unimarc-components/stories/layout/Spacer/Horizontal'
import { ShelvesData } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'
interface PositionProp {
  maxWidth: string
  position: 'relative' | 'initial'
}

export const useProdutsCarousel = ({ isMobile }: { isMobile: boolean }) => {
  const linkFontSize: FontSizeLink = isMobile ? 'sm' : 'lg'
  const positionContainer: PositionProp = {
    position: !isMobile ? 'relative' : 'initial',
    maxWidth: '1120px'
  }
  const itemsToShow = isMobile ? 2 : 5
  const itemsToSlide = isMobile ? 2 : 5
  const carouselHeaderPadding = isMobile ? '0 12px' : '0 0 24px'
  const spacerBelow: Sizes = isMobile ? 12 : 32

  return {
    linkFontSize,
    positionContainer,
    itemsToShow,
    itemsToSlide,
    carouselHeaderPadding,
    spacerBelow
  }
}

export const useShelvesData = (shelvesData, isMobile) => {
  const shelves = useMemo(() => {
    return (
      shelvesData
        ?.map((item: ShelvesData) => {
          if (!item.sellers) return undefined
          const { isPromotion, price, pricePromotion, ppum, offer } =
            statusPromotions(item)
          const promotion = {
            ...item?.priceDetail?.promotionalTag,
            backgroundColor: item?.priceDetail?.promotionalTag?.color,
            label: item?.priceDetail?.promotionalTag?.text,
            color: item?.priceDetail?.promotionalTag?.textColor
          }
          const url = formatProductUrl(item['detailUrl'] || item['slug'])
          const maxQuantity =
            item?.cartLimit > 0
              ? item?.cartLimit
              : item?.sellers?.[0]?.availableQuantity || 0

          const { measurementUnitUn, netContent, netContentLevelSmall } = item

          return {
            ...item,
            brand: item?.brand,
            format: item?.format,
            img: item?.images[0],
            inOffer: item?.sellers[0]?.inOffer,
            isMobile: isMobile,
            isPromotion: isPromotion,
            key: item?.itemId,
            likeListButtons: false,
            listPrice: item?.sellers[0]?.listPrice,
            offer: offer,
            isMxNPromotion: item?.promotion?.['type'] === 'mxn',
            ppum: `(${ppum})`,
            ppumListPrice: `(${item?.sellers[0]?.ppumListPrice})`,
            price: price,
            pricePromotion,
            promotion: {
              hasSavings: item?.promotion?.['hasSavings'],
              descriptionCode: item?.promotion?.['descriptionCode'],
              itemsRequiredForPromotion:
                item?.priceDetail?.['itemsRequiredForPromotion'],
              pricePaymentsMethods: item?.promotion?.['pricePaymentsMethods'],
              saving: item?.promotion?.['saving'],
              ...promotion
            },
            quantityButtonProps: {
              maxQuantity
            },
            title: item?.name,
            typePromotion: item?.promotion?.['descriptionCode'],
            url,
            stock: true,
            measurementUnitUn,
            netContent,
            netContentLevelSmall
          }
        })
        .filter((i) => {
          return !!i
        }) ?? []
    )
  }, [shelvesData])

  const { products: shelvesWithCoupon } = useProductsCoupon(shelves)

  return shelvesWithCoupon
}
